import Table from 'react-bootstrap/Table';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiFillDollarCircle } from "react-icons/ai";
import BTC from "../../Assets/btc.png";
import ETH from "../../Assets/eth.png";
import USDT from "../../Assets/usdt.png";
import Dollar from "../../Assets/dollar.png";

const PortfolioTable = () => {
    const [price, setPrice] = useState(null);
    const [btcAmount, setBtcAmount] = useState(null);
    const [ethAmount, setEthAmount] = useState(null);
    const [btcCurrentPrice, setBtcCurrentPrice] = useState(null);
    const [ethCurrentPrice, setEthCurrentPrice] = useState(null);
    const [total, setTotal] = useState('417,000');
    
    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await axios.get('https://api.kraken.com/0/public/Ticker?pair=XBTUSD,ETHUSD');
                let btcPrice = 3.2 * response.data.result.XXBTZUSD.c[0];
                let ethPrice = 43 * response.data.result.XETHZUSD.c[0];
                let btcValue = response.data.result.XXBTZUSD.c[0]; // current btc price
                let ethValue = response.data.result.XETHZUSD.c[0]; // current eth price

                // rounding
                btcPrice = Math.round(btcPrice * 100) / 100;
                ethPrice = Math.round(ethPrice * 100) / 100;
                btcValue = Math.round(btcValue * 100) / 100;
                ethValue = Math.round(ethValue * 100) / 100;

                //total and rounding total
                let totalPrice = btcPrice + ethPrice + 153861.95;
                totalPrice = Math.round(totalPrice * 100) / 100

                setPrice(response.data.result.XXBTZUSD.c[0]);
                setBtcCurrentPrice(btcValue);
                setEthCurrentPrice(ethValue);
                setBtcAmount(btcPrice);
                setEthAmount(ethPrice);
                setTotal(totalPrice )
               
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchPrice();
    }, []);


  return (
    <Table  bordered hover className='tableStyle'>
      <thead>
        <tr>
          <th>Asset</th>
          <th>Price (Updated Hourly)</th>
          <th>Quantity</th>
          <th>Amount in USD</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><img className="portfolioicon" src={BTC} alt="" />BTC</td>
          <td>{btcCurrentPrice}</td>
          <td>3.2</td>
          <td>${btcAmount}</td>
        </tr>
        <tr>
          <td><img  className="portfolioicon" src={ETH} alt="" />ETH</td>
          <td>{ethCurrentPrice}</td>
          <td>43</td>
          <td>${ethAmount}</td>
        </tr>
        <tr>
          <td ><img className="portfolioicon" style={{paddingRight: '5px'}} src={USDT} alt="" />USDT</td>
          <td>{1}</td>
          <td>153861.95</td>
          <td>$153861.95</td>
        </tr>
        <tr>
          <td colspan={3}><img style={{maxWidth: '6%', padding: '8px'}} src={Dollar} alt="" />Total</td>
          
          <td>${total}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default PortfolioTable;