import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TradingView from './TradingView';
import DrawChart from './DrawChart';
import Table from 'react-bootstrap/Table';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import OrderHistory from './OrderHistory';

// export const Trading = () => {

    function Trading() {

        const [price, setPrice] = useState(null);
        const [btc, setBtc] = useState(null);
        const [eth, setEth] = useState(null);
        const [total, setTotal] = useState(457200);
        
        useEffect(() => {
            const fetchPrice = async () => {
                try {
                    const response = await axios.get('https://api.kraken.com/0/public/Ticker?pair=XBTUSD,ETHUSD');
                    let btcPrice = 3.2 * response.data.result.XXBTZUSD.c[0];
                    let ethPrice = 43 * response.data.result.XETHZUSD.c[0];
                    btcPrice = Math.round(btcPrice * 100) / 100
                    ethPrice = Math.round(ethPrice * 100) / 100
                    let totalPrice = btcPrice + ethPrice + 153861.95;
                    totalPrice = Math.round(totalPrice * 100) / 100
                    setPrice(response.data.result.XXBTZUSD.c[0]);
                    setBtc(btcPrice);
                    setEth(ethPrice);
                    setTotal(totalPrice )
                   
                } catch (error) {
                    console.error('Error fetching data: ', error);
                }
            };
    
            fetchPrice();
        }, []);

    return (
        <div>
            
        <Container fluid className="margintopbottom">
          <Row  className="margintopbottom">
            <Col lg="8" className="tradingchart">
            
                <TradingViewWidget 
                theme={Themes.LIGHT}
                
                symbol="BTC" 
                autosize
                />
            </Col>
            <Col xs lg="2" className={"applyborder orderbook"}>
                <div className="title">Order Book</div>
                
                <Alert key='info' variant='info'>
                        Please Enable Level 2 Permissions to access the Order Book
                 </Alert>
            </Col>
            
            <Col xs lg="2" className="applyborder">
                
                <div className="title">Place Order</div>
                <Form>
                <fieldset disabled>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="disabledTextInput">Available: ${total}</Form.Label>
                    <Form.Control id="disabledTextInput" placeholder="Price" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control id="disabledTextInput" placeholder="Quantity" />
                </Form.Group>
                </fieldset>
                </Form>
                <Form.Label>Type</Form.Label>
                <Form.Select aria-label="Default select example">
                    <option>Limit</option>
                    <option value="1">Market</option>
                    <option value="2">Trailing Stop</option>
                    <option value="3">OCO</option>
                </Form.Select>
                    
                    <div className="margintopbottom">
                    <Row>
                        <Button variant="contained">BUY</Button>
                        <Button variant="contained" color='error'>SELL</Button>
                   </Row>
                    </div>
                    <Alert key='info' variant='info'>
                        Please Enable Level 2 Permissions to place orders
                    </Alert>
            </Col>
          </Row>

          
          <Row >
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="home" title="Open Orders (0)">
                        <p>No open orders to display.</p>
                    </Tab>
                    <Tab eventKey="profile" title="Order History (4)">
                        <OrderHistory/>
                    </Tab>
                    <Tab eventKey="contact" title="Position" disabled>
                        Tab content for Contact
                    </Tab>
                </Tabs>
            </Row>
        </Container>
        {/*
        <Container className="margintopbottom">
            <Row>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="home" title="Open Orders (0)">
                    
                    </Tab>
                    <Tab eventKey="profile" title="Order History" disabled>
                        
                    </Tab>
                    <Tab eventKey="contact" title="Position" disabled>
                        Tab content for Contact
                    </Tab>
                </Tabs>
            </Row>

        </Container> */}

        </div>
      );
}

export default Trading;