import Table from 'react-bootstrap/Table';

function OrderHistory() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Filled Time</th>
          <th>Pair</th>
          <th>Side</th>
          <th>Type</th>
          <th>Filled Price</th>
          <th>Quantity</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>10/20/2021 10:19:24</td>
          <td>BTH/USDT</td>
          <td style={{color: 'red', fontWeight: 'bold'}}>SELL</td>
          <td>Limit Order</td>
          <td>$60,813</td>
          <td>2.53</td>
          <td>$153,861.95</td>
        </tr>
        <tr>
          <td>12/11/2020 7:28:15</td>
          <td>BTH/USDT</td>
          <td style={{color: 'green', fontWeight: 'bold'}}>BUY</td>
          <td>Market Order</td>
          <td>$19,170</td>
          <td>2.53</td>
          <td>$49,866.3</td>
        </tr>
        <tr>
          <td>12/11/2020 7:14:39</td>
          <td>BTH/USDT</td>
          <td style={{color: 'green', fontWeight: 'bold'}}>BUY</td>
          <td>Market Order</td>
          <td>$19,160</td>
          <td>2.2</td>
          <td>$42,152.00</td>
        </tr>
        <tr>
          <td>12/11/2020 7:02:17</td>
          <td>BTH/USDT</td>
          <td style={{color: 'green', fontWeight: 'bold'}}>BUY</td>
          <td>Market Order</td>
          <td>$19,000</td>
          <td>1</td>
          <td>$19,000</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default OrderHistory;