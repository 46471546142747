import { About } from "../pages/About"
import { Account } from "../pages/Account"
import { Home } from "../pages/Home"
import { Login } from "../pages/Login"
import  Trading  from "../pages/Trading"
import { Verification } from "../pages/Verification"
import { Withdraw } from "../pages/Withdraw"

export const nav = [
     { path:     "/",         name: "Home",        element: <Home />,       isMenu: true,     isPrivate: false  },
     { path:     "/about",    name: "About",       element: <About />,      isMenu: true,     isPrivate: false  },
     { path:     "/login",    name: "Login",       element: <Login />,      isMenu: false,    isPrivate: false  },
     { path:     "/Trading",  name: "Trading",     element: <Trading />,    isMenu: true,     isPrivate: true  },
     { path:     "/account",  name: "Account",     element: <Account />,    isMenu: true,     isPrivate: true  },
     { path:     "/withdraw",  name: "Withdraw",     element: <Withdraw data={{a: [1,2,3], b: 'string'}}/>,    isMenu: true,     isPrivate: true  },
     { path:     "/verify",  name: "Verification",     element: <Verification data={{a: [1,2,3], b: 'string'}}/>,  isMenu: true,  isPrivate: true  }
]