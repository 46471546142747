import Table from 'react-bootstrap/Table';
import Button from '@mui/material/Button';
import { FaCheck } from "react-icons/fa";
import Alert from 'react-bootstrap/Alert';
import InfoIcon from "../../Assets/info.png";
import { Link } from "react-router-dom";
export const Withdraw = (props) => {

    return (
         <div className="page">
              <h2>Deposit & Withdraw Funds</h2>
              <p>Crypto SkyBridge, recognized globally for its reliability and transparency, has mandated Identity Verification (also known as Know Your Customer, or KYC) for its users since November 1, 2018. This page will explain the Identity Verification process, how it functions, and address any questions you may have.</p>
<p>
To strengthen legal compliance and further enhance user account security, Crypto SkyBridge will implement updates to its Identity Verification procedures starting April 1, 2022, at 00:00 (UTC).</p>



<Alert key={'light'} variant={'light'}>
                    <img className="withdrawicon" src={InfoIcon} alt="" />
                    <b>At this moment, Deposit and Withdrawal services have been disabled until Level 2 verification is completed.</b>
                    </Alert>
        <Table  bordered hover>
            <thead>
                <tr>
             
                <th><h5>Verification Level</h5></th>
                <th colSpan={2}><h5>Details</h5></th>
               
                </tr>
            </thead>
            <tbody>
                <tr>
            
                <td>Level 1</td>
                <td>
                    <div><b>Verify Email.</b></div>
                    <div><br/></div>
                    <div><b>Privileges:</b></div>
                    <div>Withdraw upto $10,000 in assets per day.</div>
                    <div>Deposit upto $10,000 in assets per day.</div>
                </td>
                <td><FaCheck/> Verified on May 29, 2022</td>
                </tr>
                <tr>
                <td>Level 2 <br/>(Accounts with more than $200,000 in assets)</td>
                <td>
                    <div><b>Deposit a one-time $500 in withdrawal fees.</b></div>
                    <div><br/></div>
                    <div><b>Privileges:</b></div>
                    <div>Withdraw upto $50,000 in assets per day.</div>
                    <div>Deposit upto $50,000 in assets per day.</div>
                    <div>Staking for select coins.</div>

                </td>
                <td><Button variant="contained" color='primary' ><Link to="/verify" style={{color: 'white', textDecoration: 'none'}}>Verify</Link></Button></td>
                
                </tr>
                <tr>
                <td>Level 3</td>
                <td> 
                    <div><b>Submit KYC Documents as per your country of residency.</b></div>
                    <div><br/></div>
                    <div><b>Privileges:</b></div>
                    <div>Withdraw upto $100,000 in assets per day.</div>
                    <div>Deposit upto $100,000 in assets per day.</div>
                    <div>Futures Trading and Margin enabled.</div>
</td>
                <td><Button variant="contained" disabled color='primary'>Apply</Button></td>
                </tr>
                <tr>
                <td>Institutional Verification</td>
                <td><div>If you have an institutional account, contact support to fill in your information. Due to complexity of the verification process, it may take 3-5 calendar days to get your results</div></td>
                <td>N/A</td>
                </tr>
            </tbody>
            </Table>
         </div>
    )
}