export const About = () => {

    return (
         <div className="page">
              <h2>About Us</h2>
              <p>We empower you to do more
              with your cryptocurrencies.</p>
              <h2>Buy. Trade. Earn. Your Way.</h2>

<p>Markets are always on the move, but peace of mind is priceless.
Crypto SkyBridge provides secure and reliable access to the world’s most popular cryptocurrencies, with some of the lowest fees in the industry.
Launched in June 2018 and headquartered in Florida, Crypto SkyBridge was established to serve U.S. and global consumers and adhere to U.S. and all local regulations.</p>
<br/><br/>
<h2>It's time to take a bow</h2>
<p> It is with great regret that we have made the decision to stop all CEX operations from October 15, UTC 04:00. We kindly ask all users to withdraw their remaining assets before October 01, UTC 04:00. </p>
<p>That’s all, with so many joys and tears, our show is over. We want to say thank you and take a bow now. We still believe a bright future about crypto innovation and some of us will still fight for it.</p>
         </div>


    )
}