import React from "react";
import AboutBackground from "../../Assets/about-background.png";
import AboutBackgroundImage from "../../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

import tradingPlatform from "../../Assets/tradingplatform.png";
import cryptos from "../../Assets/cryptos.png";

const Info = () => {
  return (
    <div>
    <div className="about-section-container">
      
      <div className="about-section-image-container">
        <img src={tradingPlatform} alt="" />
      </div>
      <div className="about-section-text-container">
       {/* <p className="primary-subheading">About</p>*/}
        <h1 className="primary-heading">
          Invest like a Pro
        </h1>
        <p className="primary-text">
          Best in class trading tools with ultra-low trading fees.
        </p>
        <p className="primary-text">
          Scalable trading solutions for institutional investors.
        </p>
        {/*<div className="about-buttons-container">
          <button className="secondary-button">Sign up now</button>
          
        </div>*/}
      </div>
      
    </div>
    <div className="about-section-container">
      
      <div className="about-section-image-container">
        <img src={cryptos} alt="" />
      </div>
      <div className="about-section-text-container">
        <h1 className="primary-heading">
          Trade 50+ Cryptocurrencies
        </h1>
        <p className="primary-text">
          Trade and store the latest cryptocurrencies.
        </p>
        
        {/*<div className="about-buttons-container">
          <button className="secondary-button">Sign up now</button>
          
        </div>*/}
      </div>
      
    </div>
    </div>
  );
};

export default Info;