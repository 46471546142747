import React from "react";
import BannerBackground from "../../Assets/home-banner-background.png";
import BannerImage from "../../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import Info from "./Info.js";
import MoreInfo from "./MoreInfo.js";
import ethAnimated from "../../Assets/ethanimated.gif";
import Companylogo from "../../Assets/companylogo.png";

export const Home = () => {
  return (
    <div className="home-container">
      {/*<Navbar />*/}
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
          Buy, Sell & Trade Cryptocurrencies
          </h1>
          <p className="primary-text">
            Millions choose <img className="homepagelogo" src={Companylogo}/>Crypto SkyBridge to buy and trade cryptocurrencies. 
          </p>
          {/*<button className="secondary-button">
            Sign up Now <FiArrowRight />{" "}
          </button>*/}
        </div>
        <div className="home-image-section">
          <img src={ethAnimated} alt="" />
        </div>
      </div>
      <Info/>
      <MoreInfo/>
    </div>
  );
};

