import Accordion from 'react-bootstrap/Accordion';
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import InfoIcon from "../../Assets/info.png";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
const ethValue = 2340;
const btcValue = 56000;
const solValue = 131;

const cryptoAddress = [
    {
        title: 'USDT (ERC20)',
        address: ['0x54ee410b8d26664cb67e002d2697ce1f934f2cd2']
    },
    {
        title: 'BTC',
        address: ['bc1qslacc7w9505uqw3098g0ml54mc9pu0vgkykftj', 'bc1qkqjfra9vgjt46zetytter5zsfufuya8gk9uz84']
    },
    {
        title: 'ETH',
        address: ['0x494D388a02bba70A37ee426d342B0617788821b1', '0x6C67C4243D62A2372ab4B237d525910A7AC89713']
    },
    
    {
        title: 'USDT (TRON)',
        address: ['TDzugFR9uyCXbHUSx2jCVfyjLqoWxAsTcK']
    },
    {
        title: 'SOLANA',
        address: ['8zeHcy6Je42zMCJXqBM4oEzYGzLWR8tUsdTjY1EDVobK', '9MqNuEBUrvKygT9iHS38MCtiz5TyfquZ7BMYkJrvFYyx']
    },
]


export const Verification = (props) => {
    let firstBtc = cryptoAddress[0].address;
    const [crypto, setCrypto ] = useState(cryptoAddress[0].title);
    const [address, setCryptoAddress] = useState(firstBtc[firstBtc.length * Math.random() | 0])
    const [quantity, setQuantity] = useState(500);
    const [btcCurrentPrice, setBtcCurrentPrice] = useState(btcValue);
    const [ethCurrentPrice, setEthCurrentPrice] = useState(ethValue);
    const [solCurrentPrice, setSolCurrentPrice] = useState(solValue);
    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await axios.get('https://api.kraken.com/0/public/Ticker?pair=XBTUSD,ETHUSD,SOLUSD');
                let btcValue = response.data.result.XXBTZUSD.c[0]; // current btc price
                let ethValue = response.data.result.XETHZUSD.c[0]; // current eth price
                let solValue = response.data.result.SOLZUSD.c[0]; 

                // rounding
                btcValue = Math.round(btcValue * 100) / 100;
                ethValue = Math.round(ethValue * 100) / 100;
                solValue = Math.round(solValue * 100) / 100;
                

                setEthCurrentPrice(ethValue);
                setBtcCurrentPrice(btcValue);
                setSolCurrentPrice(solValue);
                console.log('use effect called $$$$$$')
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchPrice();
    }, []);

    const handleChange = ({ target }) => {
        
        const index = target.options.selectedIndex;
    // const handleChange = (event) => {

    //     console.log(event.target.value.title);
    //     console.log(event.target.value.address)
        
        const newCrypto = cryptoAddress[index];
        let newTitle = newCrypto.title;
        let newAddress = newCrypto.address[newCrypto.address.length * Math.random() | 0];
        console.log(index);
        console.log(newTitle);
        console.log(newCrypto.address.toString());
        console.log(newAddress);
        setCrypto(newTitle);
        setCryptoAddress(newAddress);

        if (index === 0 || index == 3) {
            setQuantity(500)
        }
        else if (index === 1) {
            let btcAmount = 510 / btcCurrentPrice;
            console.log('1')
            console.log(btcCurrentPrice)
            console.log(btcAmount);
            btcAmount = Math.round(btcAmount * 100) / 100;
            console.log('r')
                console.log(btcAmount);
            setQuantity(btcAmount);
        }
        else if (index === 2) {
            let ethAmount = 510 / ethCurrentPrice;
            ethAmount = Math.round(ethAmount * 100) / 100;
            setQuantity(ethAmount);
        }
        else if (index === 4) {
            let solAmount = 510 / solCurrentPrice;
            solAmount = Math.round(solAmount * 100) / 100;
            setQuantity(solAmount);
        }
      };
      
      
    return (
         <div className="page">
              <h2>Verification</h2>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Step 1: Complete Verification</Accordion.Header>
                    <Accordion.Body>
                    <div className='payforverify'>
                        <div className="cryptoselect">
                            Choose Payment Method<br/><br/>
                    <select
                            onChange={handleChange}
                            value={crypto}
                            id="crypto-select"
                        >
                            {cryptoAddress.map( item => (
                                <option key={item.title} value={item.title}>
                                    {item.title}
                                </option>
                            ))}
                        </select> 
                        
                        </div>

                   
                   {/*} <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Payment</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={crypto}
                        label="Select Payment"
                        onChange={handleChange}
                        >
                        
                            {cryptoAddress.map( item => (
                                <MenuItem value={item.title}>{item.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                        <br/>
                        <h5 style={{color: '#04ce58'}}>Send {quantity} {crypto} to <br/><br/>{address}</h5>
                        <Button variant="contained" onClick={() => {navigator.clipboard.writeText(address)}}>Copy Address</Button>
                    </div>
                    
                    
                    <Alert key={'light'} variant={'light'}>
                    <img style={{maxWidth: '5%', padding: '8px'}} src={InfoIcon} alt="" />
                        Please don't refresh the page until you see confirmation message.
                    </Alert>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Step 2: Payment Confirmation</Accordion.Header>
                    <Accordion.Body>
                        <p>Payment Pending</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Step 3: Verification Confirmation</Accordion.Header>
                    <Accordion.Body>
                        <p>Verification Status: Payment Pending</p>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
         </div>
    )
}