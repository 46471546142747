import { AuthData } from "../../auth/AuthWrapper"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ApplyButton from "./ApplyButton";
import PortfolioTable from './PortfolioTable';
import ProgressBar from 'react-bootstrap/ProgressBar';
import CardGroup from 'react-bootstrap/CardGroup';
import { FiSmile } from "react-icons/fi";
import Button from '@mui/material/Button';
import USA from "../../Assets/usa.png";
import { Link } from "react-router-dom";

export const Account = () => {

     const { user } = AuthData();

     return (
          <div style={{backgroundColor: '#f0eff5'}} >
            
            <div className="page">
                <h2 style={{fontWeight: 600, color: '#344767', fontSize: '20px'}}>YOUR ACCOUNT</h2>
                <p>Hi Aaron!</p>
            </div>
            <Container>
                <Row style={{paddingBottom: '20px'}} >
                    <CardGroup>
                    
                        <Card style={{padding: '10px', color: 'white', borderRadius: '0.5rem'}} className='blueBg'>
                            <Card.Body>
                                <Card.Title>Portfolio Risk Level</Card.Title>
                                 <Card.Text>
                                    Safe <FiSmile />
                                </Card.Text>
                                <ProgressBar variant="info" now={20} />
                            </Card.Body>
                        </Card>
                   
                    
                    
                        <Card style={{padding: '10px', color: 'white', borderRadius: '0.5rem'}} className='orangeBg'>
                            <Card.Body>
                                <Card.Title>Verification Status: Level 1</Card.Title>
                                <Card.Text>
                                Verified on May 29, 2022.
                                </Card.Text>
                                <Card.Text>
                                Country: <img style={{maxWidth: '10%'}} src={USA} alt="" />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                   


                        <Card style={{padding: '10px', color: 'white',  borderRadius: '0.5rem'}} className='greenBg'>
                            <Card.Body>
                                <Card.Title>Earn 5.5% APY</Card.Title>
                                <Card.Text>
                                    Stake your coins. Unlock our best interest rate.
                                </Card.Text>
                                <Button variant="contained" color='primary' ><Link to="/verify" style={{color: 'white', textDecoration: 'none'}}>Apply</Link></Button>
                            </Card.Body>
                        </Card>

                    
                    </CardGroup>
                </Row>
                <Row  style={{paddingBottom: '20px'}}>
                    <Col>
                        <PortfolioTable/>
                    </Col>
                </Row>
                </Container>
          </div>
     )
}