import React from "react";
import PickMeals from "../../Assets/cryptos150.svg";
import ChooseMeals from "../../Assets/billion.png";
import DeliveryMeals from "../../Assets/secure.svg";

const MoreInfo = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "5 million global customers.",
      text: "From 120+ countries.",
    },
    {
      image: ChooseMeals,
      title: "$1.2 Billion trading volume.",
      text: "Quarterly Trading Volume.",
    },
    {
      image: DeliveryMeals,
      title: "100% Secured.",
      text: "We adhere to top-tier security standards and implement rigorous practices to ensure your account remains safe and secure.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Crypto is calling</p>
        <h1 className="primary-heading">Why choose us</h1>
        {/*<p className="primary-text">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>*/}
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreInfo;