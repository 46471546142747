import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthWrapper } from './auth/AuthWrapper';
// import Home from "./components/Home";
import About from "./components/pages/Info";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthWrapper />
      </BrowserRouter> 
      {/*<Home />*/}
      {/*<About />*/}
     {/* <Work />*/}
      {/*<Testimonial />*/}
      {/*<Contact />*/}
      <Footer />     
    </div>
  );
}

export default App;